import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ArrowDropDown, Add as AddIcon } from "@material-ui/icons";
import SortIcon from "@material-ui/icons/Sort";
import DnsIcon from "@material-ui/icons/Dns";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
// import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
// import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SpeedIcon from "@material-ui/icons/Speed";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import {
  closeMessageLoading,
  confirm,
  fetchNotifications,
  messageError,
  messageLoading,
  messageOk,
} from "../../modules/utils/actions";
import { connect } from "react-redux";
import {
  deleteUserMine,
  unBindUserMine,
  getCPError,
  getErrorType,
  getFaultTime,
  getInviteNodes,
  getMachineError,
  getUserMine,
  resubmitUserMine,
  setName,
  updateMachineMonitor,
} from "../../modules/userMine/actions";
import { Title } from "../../modules/title";
import Tab from "../../modules/tab";
import Table from "../../modules/table";
import {
  InviteeListItem,
  LinkListItem,
  MineNodeListItem,
} from "../../modules/listItem";
import { MineNodeDetail } from "../../modules/nodeDetail";
import EasyAnnounce from "../../modules/easyAnnounce";
import { getPreferences, updatePreferences } from "../../modules/user/actions";
import { withRouter } from "react-router-dom";
import Search from "../../common/search";
import { useThrottle } from "../../modules/hooks/tools";

const styles = (theme) => ({});

const originalActions = [
  {
    icon: <MonetizationOnIcon fontSize="small" />,
    name: "昨日效益指标",
    key: "efficiencyYesterday",
    sort: "ASC", // DESC
  },
  {
    icon: <AttachMoneyIcon fontSize="small" />,
    name: "昨日收益",
    key: "rewardYesterday",
    sort: "ASC",
  },
  {
    icon: <LocalAtmIcon fontSize="small" />,
    name: "总收益",
    key: "totalReward",
    sort: "ASC",
  },
  {
    icon: <SpeedIcon fontSize="small" />,
    name: "带宽",
    key: "bandwidth",
    sort: "ASC",
  },
  {
    icon: <DnsIcon fontSize="small" />,
    name: "名称",
    key: "userName",
    sort: "ASC",
  },
];

const originalFilters = [
  {
    name: "是否在线",
    key: "online",
    options: ["在线", "离线"],
    values: [true, false],
    filter: 0,
    hiddenName: true,
  },
  {
    name: "运营商",
    key: "isp",
    options: ["移动", "联通", "电信"],
    filter: 0,
    hiddenName: true,
  },
  {
    name: "配置状态",
    key: "config_status",
    options: ["待配置", "配置中", "配置成功", "配置失败"],
    filter: 0,
    isValue: true, // filter的值是否为vaule
    hiddenName: true,
  },
  {
    name: "验收状态",
    key: "approve_status",
    options: ["未验收", "验收中", "验收通过", "验收不通过"],
    filter: 0,
    isValue: true,
    hiddenName: true,
  },
];

const desiredOrder = ["online", "isp", "config_status", "approve_status"];

export function TableSortWrapper(props) {
  const theme = useTheme();
  // const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  // const isMiddleScreen = useMediaQuery(useTheme().breakpoints.down("md"));
  const isExSmallScreen = useMediaQuery(useTheme().breakpoints.down("xs"));

  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const anchorRef = useRef(null);
  const filterAnchorRef = useRef(null);

  const [actions, setActions] = useState(originalActions);
  const [selectedActions, setSelectedActions] = useState(actions[0]);
  const [filters, setFilters] = useState([]);
  const [searchPattern, setSearchPattern] = useState("");

  const optionList = useMemo(() => {
    const list = [];
    originalFilters
      .filter((i) => props.filterActions?.includes(i.key))
      .forEach((filter) => {
        list.push(
          { type: "ListSubheader", name: filter.name },
          ...filter.options.map((option, index) => ({
            key: filter.key,
            name: option,
            value: index,
            selected: filters.some(
              (i) => i.key === filter.key && i.filter === index
            ),
          }))
        );
      });
    return list;
  }, [originalFilters, props.filterActions]);

  const filtersInActions = useMemo(() => {
    return filters.filter((value) => props?.filterActions?.includes(value.key));
  }, [filters, props.filterActions]);

  const handleClick = useThrottle(() => {
    const index = actions.findIndex((i) => i.key === selectedActions.key);
    if (index !== -1) {
      const newAction = [...actions];
      newAction[index].sort = newAction[index].sort === "ASC" ? "DESC" : "ASC";
      setActions(newAction);
      const newSelectedActions = { ...newAction[index] };
      setSelectedActions(newSelectedActions);
      props.updateSort({
        key: newAction[index].key,
        sort: newAction[index].sort,
      });
    }
  }, 400);

  const handleChangeFilters = (key) => {
    const index = filters.findIndex((i) => i.key === key);
    if (index !== -1) {
      const newFilters = [...filters];
      newFilters[index].filter = !newFilters[index].filter;
      setFilters(newFilters);
    }
  };

  const handleClearFilters = () => {
    setFilters([]);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleFilterClose = (event) => {
    if (
      filterAnchorRef.current &&
      filterAnchorRef.current.contains(event.target)
    ) {
      return;
    }
    setFilterOpen(false);
  };

  const handleChangeSortType = (item, index) => {
    if (selectedActions.key !== actions[index].key) {
      setOpen(false);
    }
    setSelectedActions(actions[index]);
    props.updateSort({
      key: actions[index].key,
      sort: actions[index].sort,
    });
  };

  function sortByKeys(data, order) {
    // 创建一个映射以快速查找每个 key 的位置
    const orderMap = new Map(order.map((key, index) => [key, index]));

    return data.sort((a, b) => {
      const aIndex = orderMap.get(a.key);
      const bIndex = orderMap.get(b.key);

      if (aIndex === undefined) return 1;
      if (bIndex === undefined) return -1;

      return aIndex - bIndex;
    });
  }

  const handleAddFilter = (item, index) => {
    const filter = originalFilters.filter((i) => i.key === item.key);
    filter[0].filter = item.value;

    const f = filters.filter((i) => i.key !== item.key);
    const newFilters = item.selected ? [...f] : [...f, ...filter];
    setFilters(sortByKeys(newFilters, desiredOrder));
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleFilterToggle = () => {
    setFilterOpen((prevOpen) => !prevOpen);
  };

  const handleSearch = (value) => {
    setSearchPattern(value);
  };

  useEffect(() => {
    if (props.actions && Array.isArray(props.actions) && props.actions.length) {
      const newAction = originalActions.filter((i) =>
        props.actions.includes(i.key)
      );

      if (props.defaultSort) {
        const index = newAction.findIndex(
          (i) => i.key === props.defaultSort.key
        );

        if (index !== -1) {
          if (newAction[index].sort !== props.defaultSort.sort) {
            newAction[index].sort = props.defaultSort.sort;
            setActions(newAction);
          }
          setSelectedActions(newAction[index]);
        }
      }

      setActions(newAction);
    }
  }, [props.tabKey, props.actions, props.defaultSort]);

  // TODO 代码优化:这里将数据传入组件处理后返回的设计并不好，处理不当容易造成页面死循环渲染，表单搜索不好做防抖
  // 数据处理
  useEffect(() => {
    const filterList = (list) => {
      const pattern = searchPattern && searchPattern.trim();
      let resultList = [...list];
      if (pattern) {
        resultList = resultList.filter((item) => {
          for (const v in item) {
            if (
              item[v]
                ?.toString()
                .toLowerCase()
                .indexOf(pattern.toLowerCase()) >= 0
            ) {
              return true;
            }
          }
          return false;
        });
      }
      if (filters && filters.length) {
        resultList = resultList.filter((item) => {
          return filtersInActions.every((i) => {
            if (i.values) {
              if (i.key === "online") {
                return i.values[i.filter]
                  ? item[i.key] === i.values[i.filter]
                  : !item[i.key];
              }
              return item[i.key] === i.values[i.filter];
            } else if (i.isValue) {
              return item[i.key] === i.filter;
            } else {
              return item[i.key] === i.options[i.filter];
            }
          });
        });
      }
      return resultList;
    };

    const sortList = (list) => {
      if (!Array.isArray(list)) return;
      // 解决Tab切换时闪烁问题
      if (
        props.defaultSort &&
        (selectedActions.key !== props.defaultSort.key ||
          selectedActions.sort !== props.defaultSort.sort)
      )
        return;
      const sortedList = [...list];
      sortedList.sort((a, b) => {
        let result = 0;
        const pre = a[selectedActions.key] || 0;
        const next = b[selectedActions.key] || 0;
        switch (selectedActions.key) {
          case "efficiencyYesterday":
          case "rewardYesterday":
          case "totalReward":
          case "bandwidth":
            result = pre - next;
            break;
          case "userName":
            result = pre ? pre.localeCompare(next) : 0;
            break;
          default:
            return 0;
        }
        // 降序反转结果
        if (selectedActions.sort === "DESC") {
          result = -result;
        }
        return result;
      });
      props.updateList && props.updateList(sortedList);
    };

    const filterRes = filterList(props.list);
    sortList(filterRes);
  }, [props.list, selectedActions, props.defaultSort, searchPattern, filters]);

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap={isExSmallScreen ? "wrap" : "nowrap"}
      gridGap={10}
      mt={2}
      mb={2}
    >
      <Box flex={isExSmallScreen ? "0 1 100%" : "0 1 240px"}>
        <Search
          value={searchPattern}
          placeholder="模糊查找"
          onChange={handleSearch}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        flexWrap={isExSmallScreen ? "wrap" : "nowrap"}
        gridGap={10}
        flex="1 0 auto"
        width={isExSmallScreen ? "100%" : "auto"}
      >
        <Box>
          <ButtonGroup
            variant="outlined"
            color="primary"
            ref={filterAnchorRef}
            aria-label="split button"
          >
            <Button
              onClick={handleChangeFilters}
              startIcon={<FilterListIcon fontSize="small" />}
              // title={`按${selectedActions.name}${selectedActions.sort === "ASC" ? "升序" : "降序"}排列`}
            >
              筛选{filtersInActions && filtersInActions.length ? "：" : ""}
              {filtersInActions.map(
                (i, index) =>
                  (i.hiddenName ? "" : i.name + "：") +
                  i.options[i.filter] +
                  (index === filtersInActions.length - 1 ? "" : "、")
              )}
              {filtersInActions && filtersInActions.length ? (
                <Box component="span" title="清除过滤器" height="20px">
                  <ClearIcon
                    fontSize="small"
                    style={{ marginLeft: "8px" }}
                    onClick={handleClearFilters}
                  />
                </Box>
              ) : null}
            </Button>
            <Button
              size="small"
              aria-controls={filterOpen ? "split-button-menu" : undefined}
              aria-expanded={filterOpen ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleFilterToggle}
            >
              <ArrowDropDown />
            </Button>
          </ButtonGroup>
          <Popper
            open={filterOpen}
            anchorEl={filterAnchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 1 }}
            placement="bottom-end"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleFilterClose}>
                    <MenuList
                      id="split-button-menu"
                      style={{ minWidth: "120px" }}
                    >
                      {optionList.map((item, index) => {
                        if (item && item.type === "ListSubheader") {
                          return (
                            <ListSubheader
                              key={index}
                              style={{
                                lineHeight: "30px",
                                borderTop: index !== 0 ? "1px solid" : null,
                                borderTopColor:
                                  index !== 0 ? theme.palette.divider : null,
                              }}
                              disableSticky
                              onClickCapture={(e) => e.stopPropagation()}
                            >
                              {item.name}
                            </ListSubheader>
                          );
                        } else if (item) {
                          return (
                            <MenuItem
                              key={index}
                              value={item.value}
                              selected={item.selected}
                              onClick={() => handleAddFilter(item, index)}
                            >
                              <ListItemIcon style={{ minWidth: 30 }}>
                                {item.selected ? (
                                  <CheckIcon fontSize="small" />
                                ) : null}
                              </ListItemIcon>
                              <ListItemText
                                primary={item.name}
                                style={{
                                  fontSize: 14,
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              />
                            </MenuItem>
                          );
                        }
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
        <Box>
          <ButtonGroup
            variant="outlined"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button
              onClick={handleClick}
              startIcon={<SortIcon fontSize="small" />}
              endIcon={
                selectedActions.sort === "ASC" ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : selectedActions.sort === "DESC" ? (
                  <ArrowDownwardIcon fontSize="small" />
                ) : null
              }
              title={`按${selectedActions.name}${selectedActions.sort === "ASC" ? "升序" : "降序"}排列`}
            >
              排序：{selectedActions.name}
            </Button>
            <Button
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDown />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 1 }}
            placement="bottom-end"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {actions.map((i, idx) => (
                        <MenuItem
                          key={i.key}
                          selected={selectedActions.key === i.key}
                          onClick={() => handleChangeSortType(i, idx)}
                        >
                          <ListItemIcon style={{ minWidth: 30 }}>
                            {i.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={i.name}
                            style={{ fontSize: 14 }}
                          />
                          {i.info ? (
                            <ListItemIcon
                              style={{ minWidth: 20, marginLeft: 4 }}
                            >
                              <Tooltip title={i.info}>
                                <InfoOutlinedIcon
                                  fontSize="small"
                                  style={{ fontSize: "14px" }}
                                />
                              </Tooltip>
                            </ListItemIcon>
                          ) : null}
                          {/* <ListItemIcon style={{ minWidth: 20 }}>
                            {i.sort === "ASC" ? (
                              <ArrowUpwardIcon fontSize="small" />
                            ) : i.sort === "DESC" ? (
                              <ArrowDownwardIcon fontSize="small" />
                            ) : null}
                          </ListItemIcon> */}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Box>
    </Box>
  );
}

function MyReward(props) {
  const {
    userMineList,
    userMineListLoading,
    inviteNodes,
    inviteNodesLoading,
    onRequire,
    onResubmit,
    confirm,
    onDelete,
    onSetNickname,
    notifications,
    faultTime,
    updateMachineMonitor,
  } = props;
  const [openedNodeInfo, setOpenedNodeInfo] = useState(null);
  const [myNodeRowsPerPage, setMyNodeRowsPerPage] = useState(null);
  const [inviteeRowsPerPage, setInviteeRowsPerPage] = useState(null);
  const [myNodeSort, setMyNodeSort] = useState({
    key: "efficiencyYesterday",
    sort: "ASC",
  });
  const [inviteeSort, setInviteeSort] = useState({
    key: "rewardYesterday",
    sort: "ASC",
  });
  const [key, setKey] = useState(0);
  // 原始列表
  const [myNodesOriginalList, setMyNodesOriginalList] = useState([]);
  const [inviteeOriginalList, setInviteeOriginalList] = useState([]);

  // 排序过后的列表
  const [myNodesTableList, setMyNodesTableList] = useState([]);
  const [inviteeTableList, setInviteeTableList] = useState([]);

  const myNodesSortAction = [
    "efficiencyYesterday",
    "rewardYesterday",
    "totalReward",
    "bandwidth",
    "userName",
  ];
  const inviteeSortAction = ["rewardYesterday", "totalReward", "userName"];

  const myNodesFilterAction = [
    "online",
    "isp",
    "config_status",
    "approve_status",
  ];
  const inviteeFilterAction = ["config_status", "approve_status"];

  useEffect(() => {
    onRequire();
  }, []);

  useEffect(() => {
    const myNodeValue =
      props.preferences?.[
        `${props?.location?.pathname}+myNodesTable+rowsPerPage`
      ];
    myNodeValue && setMyNodeRowsPerPage(Number(myNodeValue));
    const nviteeValue =
      props.preferences?.[
        `${props?.location?.pathname}+inviteeTable+rowsPerPage`
      ];
    nviteeValue && setInviteeRowsPerPage(Number(nviteeValue));
    // 排序
    const myNodeSortValue =
      props.preferences?.[`${props?.location?.pathname}+myNodesTable+sort`];
    if (myNodeSortValue) {
      try {
        const m = JSON.parse(myNodeSortValue);
        setMyNodeSort(m);
      } catch (error) {
        console.error("parse myNodeSortValue error:", error);
      }
    }
    const nviteeSortValue =
      props.preferences?.[`${props?.location?.pathname}+inviteeTable+sort`];
    if (nviteeSortValue) {
      try {
        const n = JSON.parse(nviteeSortValue);
        setInviteeSort(n);
      } catch (error) {
        console.error("parse nviteeSortValue error:", error);
      }
    }
  }, [props.preferences, props.location]);

  // 求总带宽
  function calculateTotalBandwidth(bw) {
    if (!bw) return 0;
    const match = bw.match(/(\d+)\*(\d+)Mbps/);
    if (!match) {
      return 0;
    }

    const count = parseInt(match[1], 10);
    const bandwidth = parseInt(match[2], 10);

    return count * bandwidth;
  }

  useEffect(() => {
    userMineList &&
      Array.isArray(userMineList) &&
      setMyNodesOriginalList(
        userMineList.map((v) => ({
          ...v,
          ip: v.ssh || "",
          pearID: v.pear_id || "",
          online: v.online,
          approved: (v.approve_status || 0) === 2,
          onlineTime: v.online_time || 0,
          rewardYesterday: (v.reward_yesterday || 0) / 100,
          totalReward: (v.reward_total || 0) / 100,
          waiting:
            (v.reward_yesterday || 0) < 0 ||
            ((v.reward_yesterday || 0) === 0 && v.reward_status === 5), //结算中
          userName: v.nickname || "",
          bandwidth: calculateTotalBandwidth(v.bw),
          efficiencyYesterday: v.efficiency_yesterday,
        }))
      );
  }, [userMineList]);

  useEffect(() => {
    let sortedInviteNodes = [...inviteNodes];
    sortedInviteNodes.sort(
      (a, b) =>
        (b.inviter_reward_yesterday || 0) - (a.inviter_reward_yesterday || 0)
    );
    sortedInviteNodes &&
      Array.isArray(sortedInviteNodes) &&
      setInviteeOriginalList(
        sortedInviteNodes.map((v) => ({
          ...v,
          userName: v.user_name || "",
          nodes: (v.macs || []).length,
          rewardYesterday: (v.inviter_reward_yesterday || 0) / 100,
          totalReward: (v.inviter_reward_total || 0) / 100,
        }))
      );
  }, [inviteNodes]);

  // 解决当解绑最后一个节点时列表不刷新问题
  useEffect(() => {
    if (
      key === 0 &&
      Array.isArray(myNodesOriginalList) &&
      !myNodesOriginalList.length
    ) {
      setMyNodesTableList(myNodesOriginalList);
    } else if (
      key === 1 &&
      Array.isArray(inviteeOriginalList) &&
      !inviteeOriginalList.length
    ) {
      setInviteeTableList(inviteeOriginalList);
    }
  }, [myNodesOriginalList, inviteeOriginalList]);

  const myNodesTableParam = {
    list: myNodesTableList,
    loading: userMineListLoading,
    render: (v) => (
      <MineNodeListItem
        nodeInfo={v}
        key={v.ip || v.id}
        onClick={() => setOpenedNodeInfo(v)}
      />
    ),
    rowsPerPage: myNodeRowsPerPage,
    onRowsPerPageChange: (value) => {
      const key = `${props?.location?.pathname}+myNodesTable+rowsPerPage`;
      setMyNodeRowsPerPage(value);
      props.updatePreferences({
        preference_key: key,
        preferences: JSON.stringify(value),
      });
    },
  };

  const inviteeTableParam = {
    list: inviteeTableList,
    loading: inviteNodesLoading,
    render: (v) => <InviteeListItem nodeInfo={v} key={v.id} />,
    rowsPerPage: inviteeRowsPerPage,
    onRowsPerPageChange: (value) => {
      const key = `${props?.location?.pathname}+inviteeTable+rowsPerPage`;
      setInviteeRowsPerPage(value);
      props.updatePreferences({
        preference_key: key,
        preferences: JSON.stringify(value),
      });
    },
  };

  const handleUpdateList = (list) => {
    if (key === 0) {
      setMyNodesTableList(list);
    } else if (key === 1) {
      setInviteeTableList(list);
    }
  };

  const handleUpdateSort = (sortObj) => {
    let type = "";
    if (key === 0) {
      type = "myNodesTable";
      if (
        myNodeSort &&
        myNodeSort.key === sortObj.key &&
        myNodeSort.sort === sortObj.sort
      ) {
        return;
      }
      setMyNodeSort(sortObj);
    } else if (key === 1) {
      type = "inviteeTable";
      if (
        inviteeSort &&
        inviteeSort.key === sortObj.key &&
        inviteeSort.sort === sortObj.sort
      ) {
        return;
      }
      setInviteeSort(sortObj);
    }
    props.updatePreferences({
      preference_key: `${props?.location?.pathname}+${type}+sort`,
      preferences: JSON.stringify(sortObj),
    });
  };

  return (
    <div>
      <Title>CDN节点</Title>
      <EasyAnnounce notifications={notifications} />
      {myNodesOriginalList?.length || inviteeOriginalList?.length ? (
        <TableSortWrapper
          tabKey={key}
          list={key === 0 ? myNodesOriginalList : inviteeOriginalList}
          actions={key === 0 ? myNodesSortAction : inviteeSortAction}
          filterActions={key === 0 ? myNodesFilterAction : inviteeFilterAction}
          defaultSort={key === 0 ? myNodeSort : inviteeSort}
          updateList={handleUpdateList}
          updateSort={handleUpdateSort}
        />
      ) : null}
      <Tab
        data={
          inviteeOriginalList.length > 0
            ? [
                {
                  label: "我的节点",
                  content: <Table {...myNodesTableParam} key={0} />,
                },
                {
                  label: "推广节点",
                  content: <Table {...inviteeTableParam} key={1} />,
                },
              ]
            : [
                {
                  label: "我的节点",
                  content: <Table {...myNodesTableParam} key={2} />,
                },
              ]
        }
        onChange={(value) => setKey(value)}
      />

      <MineNodeDetail
        open={openedNodeInfo != null}
        onClose={() => setOpenedNodeInfo(null)}
        nodeInfo={openedNodeInfo}
        faultTime={faultTime}
        onResubmit={(id) => onResubmit(id)}
        onDelete={(id) => {
          confirm(
            `确定删除CDN？该操作无法撤回`,
            () => {
              onDelete(id);
              setOpenedNodeInfo(null);
            },
            false
          );
        }}
        onSetNickname={(id, nickname) => onSetNickname(id, nickname)}
        updateMachineMonitor={updateMachineMonitor}
        {...props}
        confirm={confirm}
      />
      <Title>添加节点</Title>
      <LinkListItem
        title="绑定自有节点"
        subtitle="将有专人完成程序部署，验收成功后，即可产生收益"
        to="/home/submit_node"
      />
      {/*<ActionListItem title="复制邀请链接" subtitle="朋友通过链接注册账号后，绑定的节点会成为你的推广节点" onClick={()=>this.copyText(`https://openfogos.com?usermine_inviter=${inviteCode}`)}/>*/}
    </div>
  );
}

const formatDate = function (date) {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  return y + "-" + m + "-" + d;
};

const getLastDate = function () {
  var dateTime = new Date();
  dateTime = dateTime.setDate(dateTime.getDate() - 1);
  dateTime = new Date(dateTime);
  return dateTime;
};

const mapStateToProps = (state) => {
  return {
    errorTypeMap: state.userMine.errorTypeMap || {},
    CPErrorMap: state.userMine.cpErrorMap || {},
    machineErrorMap: state.userMine.machineErrorMap || {},
    userMineList: state.userMine.userMineList || [],
    userMineListLoading: state.userMine.getUserMineStatus === "loading",
    inviteNodes: state.userMine.inviteNodes || [],
    inviteNodesLoading: state.userMine.getInviteNodesStatus === "loading",
    notifications: (state.utils.notifications || {})["CDN节点"] || [],
    faultTime: state.userMine.faultTime || formatDate(getLastDate()),
    preferences: state.user.preferences,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(getPreferences());
      dispatch(getUserMine());
      dispatch(getInviteNodes());
      dispatch(fetchNotifications("CDN节点"));
      dispatch(getFaultTime());
      dispatch(getCPError());
      dispatch(getErrorType());
    },
    onResubmit: (id) => {
      dispatch(
        resubmitUserMine(id, {
          success: () => {
            dispatch(messageOk("提交成功"));
            dispatch(getUserMine());
          },
          error: () => {
            dispatch(messageError("提交失败"));
          },
        })
      );
    },
    onDelete: (node_id, id) => {
      const action = {
        delete: {
          text: "删除",
          methods: (success, error) => deleteUserMine(id, { success, error }),
        },
        unbind: {
          text: "解绑",
          methods: (success, error) =>
            unBindUserMine({ node_id }, { success, error }),
        },
      };
      const currentAction = node_id ? action.unbind : action.delete;
      dispatch(messageLoading(`${currentAction.text}中...`));
      dispatch(
        currentAction.methods(
          () => {
            dispatch(messageOk(`${currentAction.text}成功`));
            dispatch(getUserMine());
          },
          () => {
            dispatch(messageError(`${currentAction.text}失败`));
          }
        )
      ).finally(() => {
        dispatch(closeMessageLoading());
      });
    },
    onSetNickname: (id, name) => {
      dispatch(
        setName(id, name, {
          success: () => {
            dispatch(messageOk("设置成功"));
            dispatch(getUserMine());
          },
          error: () => {
            dispatch(messageError("设置失败"));
          },
        })
      );
    },
    confirm: (message, callback, hasVcode) => {
      dispatch(confirm(message, callback, hasVcode));
    },
    messageOk: (dataStr) => {
      dispatch(messageOk(dataStr));
    },
    updateMachineMonitor: (id, status) => {
      dispatch(
        updateMachineMonitor(id, status, {
          success: () => {
            dispatch(getUserMine());
          },
        })
      );
    },
    getMachineError: (data) => {
      dispatch(
        getMachineError(
          {
            success: () => {
              dispatch(messageOk("请求成功！"));
            },
          },
          data
        )
      );
    },
    updatePreferences: (data) => {
      dispatch(
        updatePreferences(data, {
          success: () => {
            // dispatch(getPreferences());
          },
        })
      );
    },
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MyReward))
);
