import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./header";
import Footer from "./footer";
import { HomeRoutes } from "../../Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    marginBottom: theme.spacing(2),
    position: "relative",
  },
  main: {
    minWidth: 275,
    margin: "0 auto",
    zIndex: -999,
  },
  footer: {
    marginTop: theme.spacing(5),
  },
}));

export const Layout = ({ children, mainMaxWidth = 1000 }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />

      <div className={classes.content}>
        <div className={classes.main} style={{ maxWidth: mainMaxWidth }}>
          {children}
          <div className={classes.footer}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

const LayoutWithRoutes = () => {
  return (
    <Layout mainMaxWidth={1000}>
      <HomeRoutes />
    </Layout>
  );
};

export default LayoutWithRoutes;
