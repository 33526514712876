const Status = {
  LOADING: "loading",
  SUCCESS: "success",
  FAILURE: "failure",
};

export { Status as reducerStatus };

export default (state = { bw: {} }, action) => {
  let i;
  switch (action.type) {
    case "USERMINE/NODE/FAULTTIME_STARTED": {
      return { ...state, getfaultTimeStatus: Status.LOADING, faultTime: "" };
    }
    case "USERMINE/NODE/FAULTTIME_SUCCESS": {
      return {
        ...state,
        getfaultTimeStatus: Status.SUCCESS,
        faultTime: action.result,
      };
    }
    case "USERMINE/NODE/FAULTTIME_FAILURE": {
      return { ...state, getfaultTimeStatus: Status.FAILURE, faultTime: "" };
    }

    case "USERMINE/NODE/GET_STARTED": {
      return { ...state, getUserMineStatus: Status.LOADING, userMineList: [] };
    }
    case "USERMINE/NODE/GET_SUCCESS": {
      return {
        ...state,
        getUserMineStatus: Status.SUCCESS,
        userMineList: action.result,
      };
    }
    case "USERMINE/NODE/GET_FAILURE": {
      return { ...state, getUserMineStatus: Status.FAILURE, userMineList: [] };
    }

    case "USERMINE/NODE/TRAFFICTODAY_STARTED": {
      return {
        ...state,
        getUserMineTrafficTodayStatus: Status.LOADING,
        userMineTrafficToday: [],
      };
    }
    case "USERMINE/NODE/TRAFFICTODAY_SUCCESS": {
      return {
        ...state,
        getUserMineTrafficTodayStatus: Status.SUCCESS,
        userMineTrafficToday: action.result,
      };
    }
    case "USERMINE/NODE/TRAFFICTODAY_FAILURE": {
      return {
        ...state,
        getUserMineTrafficTodayStatus: Status.FAILURE,
        userMineTrafficToday: [],
      };
    }

    case "USERMINE/NODE/CPUIOTODAY_STARTED": {
      return {
        ...state,
        getUserMineCPUIOTodayStatus: Status.LOADING,
        userMineCPUIOToday: null,
      };
    }
    case "USERMINE/NODE/CPUIOTODAY_SUCCESS": {
      return {
        ...state,
        getUserMineCPUIOTodayStatus: Status.SUCCESS,
        userMineCPUIOToday: action.result,
      };
    }
    case "USERMINE/NODE/CPUIOTODAY_FAILURE": {
      return {
        ...state,
        getUserMineCPUIOTodayStatus: Status.FAILURE,
        userMineCPUIOToday: null,
      };
    }

    case "USERMINE/NODE/RETRANSTODAY_STARTED": {
      return {
        ...state,
        getUserMineRetransTodayStatus: Status.LOADING,
        userMineRetransToday: null,
      };
    }
    case "USERMINE/NODE/RETRANSTODAY_SUCCESS": {
      return {
        ...state,
        getUserMineRetransTodayStatus: Status.SUCCESS,
        userMineRetransToday: action.result,
      };
    }
    case "USERMINE/NODE/RETRANSTODAY_FAILURE": {
      return {
        ...state,
        getUserMineRetransTodayStatus: Status.FAILURE,
        userMineRetransToday: null,
      };
    }

    case "USERMINE/NODE/TRAFFICYESTERDAY_STARTED": {
      return {
        ...state,
        getUserMineTrafficYesterdayStatus: Status.LOADING,
        userMineTrafficYesterday: [],
      };
    }
    case "USERMINE/NODE/TRAFFICYESTERDAY_SUCCESS": {
      return {
        ...state,
        getUserMineTrafficYesterdayStatus: Status.SUCCESS,
        userMineTrafficYesterday: action.result,
      };
    }
    case "USERMINE/NODE/TRAFFICYESTERDAY_FAILURE": {
      return {
        ...state,
        getUserMineTrafficYesterdayStatus: Status.FAILURE,
        userMineTrafficYesterday: [],
      };
    }

    case "USERMINE/NODE/CPUIOYESTERDAY_STARTED": {
      return {
        ...state,
        getUserMineCPUIOYesterdayStatus: Status.LOADING,
        userMineCPUIOYesterday: null,
      };
    }
    case "USERMINE/NODE/CPUIOYESTERDAY_SUCCESS": {
      return {
        ...state,
        getUserMineCPUIOYesterdayStatus: Status.SUCCESS,
        userMineCPUIOYesterday: action.result,
      };
    }
    case "USERMINE/NODE/CPUIOYESTERDAY_FAILURE": {
      return {
        ...state,
        getUserMineCPUIOYesterdayStatus: Status.FAILURE,
        userMineCPUIOYesterday: null,
      };
    }

    case "USERMINE/NODE/RETRANSYESTERDAY_STARTED": {
      return {
        ...state,
        getUserMineRetransYesterdayStatus: Status.LOADING,
        userMineRetransYesterday: null,
      };
    }
    case "USERMINE/NODE/RETRANSYESTERDAY_SUCCESS": {
      return {
        ...state,
        getUserMineRetransYesterdayStatus: Status.SUCCESS,
        userMineRetransYesterday: action.result,
      };
    }
    case "USERMINE/NODE/RETRANSYESTERDAY_FAILURE": {
      return {
        ...state,
        getUserMineRetransYesterdayStatus: Status.FAILURE,
        userMineRetransYesterday: null,
      };
    }

    case "USERMINE/NODE/CHARGINGBWS/LASTWEEK_STARTED": {
      return {
        ...state,
        getUserMineChargingBWsLastWeekStatus: Status.LOADING,
        userMineChargingBWsLastWeek: [],
      };
    }
    case "USERMINE/NODE/CHARGINGBWS/LASTWEEK_SUCCESS": {
      return {
        ...state,
        getUserMineChargingBWsLastWeekStatus: Status.SUCCESS,
        userMineChargingBWsLastWeek: action.result,
      };
    }
    case "USERMINE/NODE/CHARGINGBWS/LASTWEEK_FAILURE": {
      return {
        ...state,
        getUserMineChargingBWsLastWeekStatus: Status.FAILURE,
        userMineChargingBWsLastWeek: [],
      };
    }

    case "USERMINE/NODE/CHARGINGBWS/LASTMONTH_STARTED": {
      return {
        ...state,
        getUserMineChargingBWsLastMonthStatus: Status.LOADING,
        userMineChargingBWsLastMonth: [],
      };
    }
    case "USERMINE/NODE/CHARGINGBWS/LASTMONTH_SUCCESS": {
      return {
        ...state,
        getUserMineChargingBWsLastMonthStatus: Status.SUCCESS,
        userMineChargingBWsLastMonth: action.result,
      };
    }
    case "USERMINE/NODE/CHARGINGBWS/LASTMONTH_FAILURE": {
      return {
        ...state,
        getUserMineChargingBWsLastMonthStatus: Status.FAILURE,
        userMineChargingBWsLastMonth: [],
      };
    }

    case "USERMINE/CASH/GET_STARTED": {
      return { ...state, getCashStatus: Status.LOADING, cash: {} };
    }
    case "USERMINE/CASH/GET_SUCCESS": {
      return { ...state, getCashStatus: Status.SUCCESS, cash: action.result };
    }
    case "USERMINE/CASH/GET_FAILURE": {
      return { ...state, getCashStatus: Status.FAILURE, cash: {} };
    }

    case "USERMINE/CASH/HISTORY_STARTED": {
      return { ...state, getCashHistoryStatus: Status.LOADING };
    }
    case "USERMINE/CASH/HISTORY_SUCCESS": {
      return {
        ...state,
        getCashHistoryStatus: Status.SUCCESS,
        cashHistory: [...(state.cashHistory || []), ...action.result],
      };
    }
    case "USERMINE/CASH/HISTORY_FAILURE": {
      return { ...state, getCashHistoryStatus: Status.FAILURE };
    }

    case "USERMINE/WITHDRAW/GET_STARTED": {
      return {
        ...state,
        getWithdrawLogsStatus: Status.LOADING,
        withdrawLogs: [],
      };
    }
    case "USERMINE/WITHDRAW/GET_SUCCESS": {
      return {
        ...state,
        getWithdrawLogsStatus: Status.SUCCESS,
        withdrawLogs: action.result,
      };
    }
    case "USERMINE/WITHDRAW/GET_FAILURE": {
      return {
        ...state,
        getWithdrawLogsStatus: Status.FAILURE,
        withdrawLogs: [],
      };
    }

    case "USERMINE/WITHDRAW/CHECK_STARTED": {
      return {
        ...state,
        checkWithdrawLogStatus: Status.LOADING,
        checkWithdrawLog: {},
      };
    }
    case "USERMINE/WITHDRAW/CHECK_SUCCESS": {
      return {
        ...state,
        checkWithdrawLogStatus: Status.SUCCESS,
        checkWithdrawLog: action.result,
      };
    }
    case "USERMINE/WITHDRAW/CHECK_FAILURE": {
      return {
        ...state,
        checkWithdrawLogStatus: Status.FAILURE,
        checkWithdrawLog: {},
      };
    }

    case "USERMINE/WITHDRAW/PREPARE_STARTED": {
      return {
        ...state,
        prepareWithdrawStatus: Status.LOADING,
        prepareWithdrawID: 0,
      };
    }
    case "USERMINE/WITHDRAW/PREPARE_SUCCESS": {
      return {
        ...state,
        prepareWithdrawStatus: Status.SUCCESS,
        prepareWithdrawID: action.result,
      };
    }
    case "USERMINE/WITHDRAW/PREPARE_FAILURE": {
      return {
        ...state,
        prepareWithdrawStatus: Status.FAILURE,
        prepareWithdrawID: 0,
      };
    }

    case "USERMINE/CM/GET_STARTED": {
      return { ...state, getCMStatus: Status.LOADING, collectionMethods: [] };
    }
    case "USERMINE/CM/GET_SUCCESS": {
      return {
        ...state,
        getCMStatus: Status.SUCCESS,
        collectionMethods: action.result || [],
      };
    }
    case "USERMINE/CM/GET_FAILURE": {
      return { ...state, getCMStatus: Status.FAILURE, collectionMethods: [] };
    }

    case "USERMINE/UNIONPAY/GET_STARTED": {
      return { ...state, getCMStatus: Status.LOADING, unionpayMethods: [] };
    }
    case "USERMINE/UNIONPAY/GET_SUCCESS": {
      return {
        ...state,
        getCMStatus: Status.SUCCESS,
        unionpayMethods: action.result || [],
      };
    }
    case "USERMINE/UNIONPAY/GET_FAILURE": {
      return { ...state, getCMStatus: Status.FAILURE, unionpayMethods: [] };
    }

    case "USERMINE/UNIONPAY/DELETE_STARTED": {
      return { ...state, getDeleteStatus: Status.LOADING };
    }
    case "USERMINE/UNIONPAY/DELETE_SUCCESS": {
      return {
        ...state,
        getDeleteStatus: Status.SUCCESS,
        deleteResult: action.result || [],
      };
    }
    case "USERMINE/UNIONPAY/DELETE_FAILURE": {
      return { ...state, getDeleteStatus: Status.FAILURE };
    }
    case "USERMINE/OPENID/RESET_STARTED": {
      return { ...state, resetOpenidStatus: Status.LOADING };
    }
    case "USERMINE/OPENID/RESET_SUCCESS": {
      return { ...state, resetOpenidStatus: Status.SUCCESS };
    }
    case "USERMINE/OPENID/RESET_FAILURE": {
      return { ...state, resetOpenidStatus: Status.FAILURE };
    }

    case "USERMINE/OPENID/GET_STARTED": {
      return { ...state, getOpenidStatus: Status.LOADING, openid: "" };
    }
    case "USERMINE/OPENID/GET_SUCCESS": {
      return {
        ...state,
        getOpenidStatus: Status.SUCCESS,
        openid: action.result,
      };
    }
    case "USERMINE/OPENID/GET_FAILURE": {
      return { ...state, getOpenidStatus: Status.FAILURE };
    }

    case "USERMINE/INVITE/CODE_STARTED": {
      return { ...state, getInviteCodeStatus: Status.LOADING, inviteCode: "" };
    }
    case "USERMINE/INVITE/CODE_SUCCESS": {
      return {
        ...state,
        getInviteCodeStatus: Status.SUCCESS,
        inviteCode: action.result,
      };
    }
    case "USERMINE/INVITE/CODE_FAILURE": {
      return { ...state, getInviteCodeStatus: Status.FAILURE };
    }
    case "USERMINE/INVITE/NODES_STARTED": {
      return {
        ...state,
        getInviteNodesStatus: Status.LOADING,
        inviteNodes: [],
      };
    }
    case "USERMINE/INVITE/NODES_SUCCESS": {
      return {
        ...state,
        getInviteNodesStatus: Status.SUCCESS,
        inviteNodes: action.result,
      };
    }
    case "USERMINE/INVITE/NODES_FAILURE": {
      return { ...state, getInviteNodesStatus: Status.FAILURE };
    }

    case "USERMINE/NODE/MAC/TRAFFIC_STARTED": {
      return {
        ...state,
        getMacTrafficStatus: Status.LOADING,
        userMineTrafficToday: [],
      };
    }
    case "USERMINE/NODE/MAC/TRAFFIC_SUCCESS": {
      return {
        ...state,
        getMacTrafficStatus: Status.SUCCESS,
        macTraffic: action.result,
      };
    }
    case "USERMINE/NODE/MAC/TRAFFIC_FAILURE": {
      return {
        ...state,
        getMacTrafficStatus: Status.FAILURE,
        userMineTrafficToday: [],
      };
    }
    case "USERMINE/ERROR_TYPE/GET_STARTED": {
      return { ...state, getErrorTypeStatus: Status.LOADING, errorTypeMap: {} };
    }
    case "USERMINE/ERROR_TYPE/GET_SUCCESS": {
      let m = {};
      const result = action.result;
      for (let i = 0; i < result.length; i++) {
        m[result[i].id] = result[i];
      }

      return { ...state, getErrorTypeStatus: Status.SUCCESS, errorTypeMap: m };
    }
    case "USERMINE/ERROR_TYPE/GET_FAILURE": {
      return { ...state, getErrorTypeStatus: Status.FAILURE };
    }

    case "USERMINE/CPERROR/GET_STARTED": {
      return { ...state, getErrorTypeStatus: Status.LOADING };
    }
    case "USERMINE/CPERROR/GET_SUCCESS": {
      let m = {};
      const result = action.result;
      for (let i = 0; i < result.length; i++) {
        m[result[i].id] = result[i];
      }
      return { ...state, getErrorTypeStatus: Status.SUCCESS, cpErrorMap: m };
    }
    case "USERMINE/CPERROR/GET_FAILURE": {
      return { ...state, getErrorTypeStatus: Status.FAILURE };
    }

    case "USERMINE/MACHINE_ERROR/GET_STARTED": {
      return {
        ...state,
        getErrorTypeStatus: Status.LOADING,
        machineErrorMap: {},
      };
    }
    case "USERMINE/MACHINE_ERROR/GET_SUCCESS": {
      let m = {};
      const result = action.result;
      for (let i = 0; i < result.length; i++) {
        if (!m[result[i].machine_id]) {
          m[result[i].machine_id] = [];
        }
        m[result[i].machine_id].push(result[i]);
      }
      return {
        ...state,
        getErrorTypeStatus: Status.SUCCESS,
        machineErrorMap: m,
      };
    }
    case "USERMINE/MACHINE_ERROR/GET_FAILURE": {
      return { ...state, getErrorTypeStatus: Status.FAILURE };
    }

    case "USERMINE/MAC/GET_STARTED": {
      return { ...state, getMacInfoStatus: Status.LOADING, macInfo: {} };
    }
    case "USERMINE/MAC/GET_SUCCESS": {
      let m = {};
      const result = action.result;
      for (let i = 0; i < result.length; i++) {
        const key = result[i]["mac_addr"].toLocaleLowerCase();
        m[key] = result[i];
      }
      return { ...state, getMacInfoStatus: Status.SUCCESS, macInfo: m };
    }
    case "USERMINE/MAC/GET_FAILURE": {
      return { ...state, getMacInfoStatus: Status.FAILURE };
    }

    case "USERMINE/STORAGE/GET_STARTED": {
      return { ...state, getStorageStatus: Status.LOADING, storage: {} };
    }
    case "USERMINE/STORAGE/GET_SUCCESS": {
      // console.log("handle usermine storage", Date.now());
      let m = {};
      // { name,80id,busid,uuid,snid,  c0~c287 }
      const data = action.result;
      // TODO 如果不存在 io_rate中，是否展示
      for (let i = 0; i < data["io_rate"].length; i++) {
        const tmp = data["io_rate"][i];
        if (!m[tmp["pear_id"]]) {
          m[tmp["pear_id"]] = {};
        }

        m[tmp["pear_id"]][tmp["name"]] = {
          name: tmp["name"],
          p80id: tmp["80id"],
          busid: tmp["busid"],
          uuid: tmp["uuid"],
          snid: tmp["snid"] || "",
          io_rate: tmp["c"],
        };
      }

      for (let i = 0; i < data["io_times"].length; i++) {
        const tmp = data["io_times"][i];
        m[tmp["pear_id"]][tmp["name"]]["io_times"] = tmp["c"];
      }
      for (let i = 0; i < data["iorl_rate"].length; i++) {
        const tmp = data["iorl_rate"][i];
        m[tmp["pear_id"]][tmp["name"]]["iorl_rate"] = tmp["c"];
      }
      for (let i = 0; i < data["iorl_times"].length; i++) {
        const tmp = data["iorl_times"][i];
        m[tmp["pear_id"]][tmp["name"]]["iorl_times"] = tmp["c"];
      }
      for (let i = 0; i < data["iowl_rate"].length; i++) {
        const tmp = data["iowl_rate"][i];
        m[tmp["pear_id"]][tmp["name"]]["iowl_rate"] = tmp["c"];
      }
      for (let i = 0; i < data["iowl_times"].length; i++) {
        const tmp = data["iowl_times"][i];
        m[tmp["pear_id"]][tmp["name"]]["iowl_times"] = tmp["c"];
      }
      for (let i = 0; i < data["io_temp"].length; i++) {
        const tmp = data["io_temp"][i];
        m[tmp["pear_id"]][tmp["name"]]["io_temp"] = tmp["c"];
      }
      // console.log("end", Date.now());

      return { ...state, getStorageStatus: Status.SUCCESS, storage: m };
    }
    case "USERMINE/STORAGE/GET_FAILURE": {
      return { ...state, getStorageStatus: Status.FAILURE };
    }

    default: {
      return state;
    }
  }
};
