import React, { useEffect, useMemo, useState } from "react";
import Markdwon from "./renderer";
import NavigationPane from "./navigationPane";
import "github-markdown-css/github-markdown-light.css";
import {
  Box,
  Divider,
  useTheme,
  useMediaQuery,
  IconButton,
  Drawer,
} from "@material-ui/core";
// import AppBar from "@material-ui/core/AppBar";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
// import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import { getDocuments } from "./actions";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";

const Document = ({ history, path }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { documents } = useSelector((state) => ({
    documents: state.document.documents,
  }));

  const [md, setMD] = useState("");
  const [openNavigationPane, setOpenNavigationPane] = useState(false);

  // const [value, setValue] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const splitBreadcrumbPath = (path) => {
    if (typeof path !== "string" || !path.startsWith("/")) {
      return;
    }

    const result = [];
    let currentPath = "";

    const parts = path.split("/").filter((part) => part.length > 0);

    for (const part of parts) {
      currentPath += `/${part}`; // 拼接当前部分
      result.push(currentPath); // 将当前路径添加到结果数组
    }

    return result;
  };

  const breadcrumbAry = useMemo(() => {
    if (!path || !documents) return;
    const pathNameMap = documents.reduce((p, c) => {
      return {
        ...p,
        [c.path]: c.name,
      };
    }, {});
    const pathAry = splitBreadcrumbPath("/" + path);
    return pathAry?.map((i) => ({
      label: pathNameMap[i],
      href: "/help" + i,
    }));
  }, [documents, path]);

  const handleFileOpen = (file) => {
    history.push(`/help${file.path}`);
    setOpenNavigationPane(false);
  };

  const handleToggleNavigationPane = (flag) => {
    setOpenNavigationPane(flag ?? !openNavigationPane);
  };

  useEffect(() => {
    dispatch(getDocuments());
  }, []);

  useEffect(() => {
    const currentDoc = documents?.find((doc) => {
      return doc.path === "/" + path;
    });
    if (currentDoc?.url) {
      try {
        fetch("https://" + currentDoc.url, { method: "GET" }).then((res) => {
          res.text().then((data) => {
            setMD(data);
          });
        });
      } catch (error) {}
    } else {
      setMD("");
    }
  }, [documents, path]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      position="relative"
      margin="0 auto"
    >
      {!isMobile && (
        <NavigationPane
          initialCurrentPath={"/" + path}
          rawFiles={documents}
          onFileOpen={handleFileOpen}
        />
      )}
      {/* <Paper square style={{ marginBottom: "10px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="系统盘制作教程" />
          <Tab label="新用户绑定节点" />
          <Tab label="常见问题" />
          <Tab label="上机教程" />
          <Tab label="收益发放及提现规则" />
        </Tabs>
      </Paper> */}
      {/* <AppBar position="static">
      </AppBar> */}

      {/* <Box width="1000px" p={2} style={{ backgroundColor: "white" }}> */}
      <Box
        flex={1}
        width="100%"
        maxWidth={1000}
        p={2}
        style={{ backgroundColor: "#fff" }}
      >
        <Box display="flex" alignItems="center" pb={1}>
          {isMobile && (
            <IconButton
              size="small"
              style={{ marginRight: "5px" }}
              onClick={() => handleToggleNavigationPane()}
            >
              <MenuOpenIcon />
            </IconButton>
          )}
          <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbAry?.map((item, index) => (
              <Typography
                key={item.label}
                color={
                  index === breadcrumbAry.length - 1 ? "textPrimary" : "inherit"
                }
              >
                {item.label}
              </Typography>
            ))}
          </Breadcrumbs>
        </Box>
        <Divider style={{ marginBottom: "8px" }} />
        <Markdwon content={md} />
      </Box>
      {isMobile && (
        <Drawer
          anchor="left"
          open={openNavigationPane}
          onClose={() => handleToggleNavigationPane(false)}
        >
          <NavigationPane
            initialCurrentPath={"/" + path}
            rawFiles={documents}
            isDrawer={true}
            onFileOpen={handleFileOpen}
            onToggleDrawer={() => handleToggleNavigationPane(false)}
          />
        </Drawer>
      )}
    </Box>
  );
};

export default withRouter(Document);
