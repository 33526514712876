import React, { useEffect, useState } from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[400],
      borderRadius: 3,
    },
  },
  label: {
    paddingLeft: 0,
    borderRadius: 4,
  },
  treeLabel: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    // 禁用ListItem选中与悬浮样式
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "transparent",
    },
  },
  listItemIcon: {
    minWidth: "28px",
  },
  // selected: {
  //   "&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
  //     backgroundColor: "transparent",
  //   },
  //   "&.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label": {
  //     backgroundColor: "transparent",
  //   },
  // },
  group: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    marginLeft: "7px",
    paddingLeft: "18px",
  },
  iconContainer: {
    width: 0,
  },
  labelText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.primary,
    fontSize: "0.875rem",
  },
}));

const FolderTree = ({
  folder,
  expanded,
  currentPath,
  onFileOpen,
  onLabelClick,
}) => {
  const classes = useStyles();

  const isSelected = currentPath === folder.path;

  return (
    <TreeItem
      nodeId={folder.path}
      label={
        <ListItem button selected={isSelected} className={classes.treeLabel}>
          {folder.isDirectory ? (
            <ListItemIcon className={classes.listItemIcon}>
              {expanded.includes(folder.path) ? (
                <FolderOpenIcon fontSize="small" color="action" />
              ) : (
                <FolderIcon fontSize="small" color="action" />
              )}
            </ListItemIcon>
          ) : null}

          {/* 文本 */}
          <ListItemText
            primary={
              <Typography
                // variant={folder.isDirectory ? "subtitle2" : "body2"}
                variant="body2"
                color={
                  !folder.isDirectory
                    ? isSelected
                      ? "primary"
                      : "textSecondary"
                    : "textPrimary"
                }
                style={{ fontWeight: folder.isDirectory ? "600" : "" }}
              >
                {folder.name}
              </Typography>
            }
          />
        </ListItem>
      }
      classes={{
        label: classes.label,
        // selected: classes.selected,
        group: classes.group,
        iconContainer: !folder.isDirectory ? classes.iconContainer : null,
      }}
      onLabelClick={() => onLabelClick(folder)}
    >
      {folder.isDirectory &&
        folder.subDirectories.map((item) => (
          <FolderTree
            key={item.path}
            folder={item}
            expanded={expanded}
            currentPath={currentPath}
            onFileOpen={onFileOpen}
            onLabelClick={(f) => onLabelClick(f)}
          />
        ))}
    </TreeItem>
  );
};

export default function FolderTreeView({
  initialCurrentPath,
  folders,
  isDrawer,
  onFileOpen,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState(initialCurrentPath);
  const [currentPath, setCurrentPath] = useState(initialCurrentPath);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleClick = (folder) => {
    if (folder.isDirectory) {
    } else {
      setSelected(folder.path);
      setCurrentPath(folder.path);
      onFileOpen(folder);
    }
  };

  // 默认只展开一层 和 当前所在目录
  useEffect(() => {
    const folderPath = folders?.filter((i) => i.isDirectory).map((i) => i.path);
    const currentFolderPath = initialCurrentPath?.substring(
      0,
      initialCurrentPath?.lastIndexOf("/")
    );
    // TODO fix: 当前所在目录所有父级
    if (folderPath.includes(currentFolderPath)) {
      setExpanded(folderPath);
    } else {
      setExpanded([currentFolderPath, ...folderPath]);
    }
  }, [folders]);

  return (
    <TreeView
      expanded={expanded}
      selected={selected}
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      className={classes.root}
      style={{ height: isDrawer ? "100%" : "calc(100% - 140px)" }}
      onNodeToggle={handleToggle}
    >
      {folders.map((folder) => (
        <FolderTree
          key={folder.path}
          folder={folder}
          expanded={expanded}
          currentPath={currentPath}
          onFileOpen={onFileOpen}
          onLabelClick={handleClick}
        />
      ))}
    </TreeView>
  );
}
