import React, { useCallback, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    padding: "2px 4px",
    backgroundColor: "transparent",
    transition: "border-color .2s",
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 6,
  },
}));

export default function CustomizedInputBase(props) {
  const {
    placeholder,
    onChange,
    onSearch,
    value,
    className,
    size = "small",
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [focus, setFocus] = useState(false);
  const inputRef = useRef();
  const onKeyUp = (event) => {
    if (event.keyCode === 13) {
      onSearch && onSearch(value);
    }
  };

  const handleSearch = () => {
    onSearch && onSearch(value);
  };

  const handleClear = useCallback(() => {
    onChange && onChange("");
  }, [onChange]);

  const handleFocus = () => {
    inputRef.current.focus();
  };

  return (
    <Paper
      className={`${classes.root} ${className}`}
      elevation={1}
      variant="outlined"
      style={{
        borderColor: focus ? theme.palette.primary.main : null,
        borderWidth: size === "medium" ? 2 : 1,
      }}
      onClick={handleFocus}
    >
      <InputBase
        inputRef={inputRef}
        className={classes.input}
        style={{ fontSize: size === "medium" ? "" : "0.9rem" }}
        placeholder={placeholder}
        value={value}
        inputProps={{ "aria-label": placeholder, maxLength: 1024 }}
        onChange={(e) => onChange(e.target.value)}
        onKeyUp={onKeyUp}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="clear"
        size={size}
        onClick={handleClear}
        style={{
          display: focus && value !== "" ? "block" : "none",
        }}
        onMouseDown={(e) => e.preventDefault()}
        onMouseUp={(e) => e.preventDefault()}
      >
        <ClearIcon fontSize={size} />
      </IconButton>
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        size={size}
        onClick={handleSearch}
      >
        <SearchIcon fontSize={size} />
      </IconButton>
    </Paper>
  );
}
