const Status = {
  LOADING: "loading",
  SUCCESS: "success",
  FAILURE: "failure",
};

export { Status as reducerStatus };

export default (state = { info: {}, coin: {} }, action) => {
  switch (action.type) {
    case "DOCUMENT/GET_STARTED": {
      return { ...state, getDocumentsStatus: Status.LOADING };
    }
    case "DOCUMENT/GET_SUCCESS": {
      return {
        ...state,
        getDocumentsStatus: Status.SUCCESS,
        documents: action.result,
      };
    }
    case "DOCUMENT/GET_FAILURE": {
      return { ...state, getDocumentsStatus: Status.FAILURE };
    }
    default: {
      return state;
    }
  }
};
