import xhr from "../../Xhr";

const API = () => ({
  document: {
    get: {
      url: process.env.REACT_APP_NMSAPI_SERVER + `/documents`,
      method: "GET",
    },
  },
});

export const getDocuments = () => {
  return xhr({
    api: API().document.get,
    action: "DOCUMENT/GET",
  });
};
