import React, { useMemo, useEffect } from "react";
import { Marked } from "marked";
import markedAlert from "marked-alert";
import { markedHighlight } from "marked-highlight";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import markedKatex from "marked-katex-extension";
import markedFootnote from "marked-footnote";
import { createDirectives, presetDirectiveConfigs } from "marked-directive";
import { containerConfig, youtubeDirective } from "./extend/containerConfig.js";
import superscript from "./extend/superscript.js";
import subscript from "./extend/subscript.js";
// import markedCodePreview from "marked-code-preview";
import markedMoreLists from "marked-more-lists";
import extendedTables from "marked-extended-tables/src/index.js";
import Zmage from "react-zmage";
import { renderToString } from "react-dom/server";
import { parseContent, TabsComponent } from "./extend/tabbed.js";
// import MarkedReact from "marked-react";
import { useMediaQuery, useTheme } from "@material-ui/core";

const Markdown = ({ content }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const renderer = {
    image({ type, raw, href, title, text }) {
      const zmageComponent = (
        <Zmage src={href} alt={text} className="zmage-container" />
      );
      return renderToString(zmageComponent);
    },
  };
  const marked = useMemo(() => {
    const marked = new Marked(
      {
        gfm: true, // 启用 GitHub Flavored Markdown
        breaks: true, // 换行符解析为 <br>
        xhtml: true, // 使用自闭合标签
        // extensions: [getContainerConfig()],
        extensions: [superscript, subscript],
      },
      markedHighlight({
        emptyLangClass: "hljs",
        langPrefix: "hljs language-",
        highlight(code, lang, info) {
          const language = hljs.getLanguage(lang) ? lang : "plaintext";
          return hljs.highlight(code, { language }).value;
        },
      })
    );

    marked
      .use(markedAlert())
      .use(
        markedKatex({
          throwOnError: false,
        })
      )
      .use(
        createDirectives([
          ...presetDirectiveConfigs,
          containerConfig,
          youtubeDirective,
          //   { level: "container", marker: ":::" },
          //   { level: "container", marker: "\\+\\+\\+" },
        ])
      )
      .use(markedFootnote())
      //   .use(markedCodePreview())
      .use(markedMoreLists())
      .use(extendedTables())
      .use({ renderer });

    // return marked.parse(content || "");
    return marked;
  }, [content]);

  const html = useMemo(() => {
    return parseContent(content || "");
  }, [marked]);

  useEffect(() => {
    document.querySelectorAll(".zmage-container").forEach((element) => {
      if (!element.getAttribute("zmage") && element.getAttribute("src")) {
        element.style.cursor = "zoom-in";
        element.setAttribute("zmage", Date.now());
        element.addEventListener("click", () =>
          // TODO fix Zmage 打印console.log("getBrowsingStyle",c)
          Zmage.browsing({
            // ...defProp,
            coverRef: { current: element },
            src: element.getAttribute("src"),
            backdrop: "rgba(0, 0, 0, 0.7)",
            preset: isMobile ? "mobile" : "desktop",
          })
        );
      }
    });
  }, [html]);

  return (
    <article
      className="markdown-body"
      // dangerouslySetInnerHTML={{ __html: html }}
    >
      {html.map((part, index) => {
        if (part.type === "text") {
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: marked?.parse(part.content) }}
            />
          );
        } else if (part.type === "tabs") {
          return <TabsComponent key={index} tabs={part.tabs} marked={marked} />;
        }
        return null;
      })}
    </article>
  );
};

export default Markdown;
