/*
 * @Author: Tperam
 * @Date: 2021-04-25 10:18:05
 * @LastEditTime: 2022-04-07 18:40:44
 * @LastEditors: Tperam
 * @Description:
 * @FilePath: \openfogos\src\pages\help.js
 */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchNotifications } from "../../modules/utils/actions";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Container,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// import Search from "../../common/search";
// import { useSelector, useDispatch } from "react-redux";
// import { getDocuments } from "../../modules/document/actions";
import { Layout } from "../../components/layout";
import { DescriptionsHeader } from "../download/Download";
import { useStyles } from "../download/Download";

function HelpCenterCard({ item, onViewMore }) {
  const classes = useStyles();

  return (
    <Card className={classes.card} style={{ height: "100%" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {item.title}
        </Typography>

        <List className={classes.list}>
          {item.articles.map((article, index) => (
            <ListItem
              key={article.title}
              className={classes.listItem}
              component="a"
              href={article.link}
              target="_blank"
              rel="noopener"
            >
              <ListItemIcon style={{ minWidth: "24px" }}>
                <FiberManualRecordIcon style={{ fontSize: "12px" }} />
              </ListItemIcon>
              <ListItemText
                primary={article.title}
                classes={{ primary: classes.articleTitle }}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>

      <CardActions className={classes.cardActions}>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={onViewMore}
        >
          查看更多
        </Button>
      </CardActions>
    </Card>
  );
}

function MyReward({ onRequire, notifications }) {
  const classes = useStyles();
  // const dispatch = useDispatch();

  // const [searchTerm, setSearchTerm] = useState("");
  // const { documents } = useSelector((state) => ({
  //   documents: state.document.documents,
  // }));

  // useEffect(() => {
  //   dispatch(getDocuments());
  // }, []);

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  const data1 = [
    {
      title: "PearOS 上机指南",
      articles: [
        { title: "系统盘制作教程", link: "/help/pearos/make_system_drive.md" },
        {
          title: "PearOS Ubuntu 版系统初始化教程",
          link: "/help/pearos/init.md",
        },
        {
          title: "新用户绑定节点",
          link: "/help/pearos/bind_node.md",
        },
      ],
    },
    {
      title: "PearOS Ubuntu 版刷机教程（以下任一）",
      articles: [
        { title: "方案一：IMG镜像烧录", link: "/help/pearos/flush/img.md" },
        {
          title: "方案二：ISO镜像安装",
          link: "/help/pearos/flush/iso.md",
        },
        {
          title: "方案三：DiskGenius镜像还原安装",
          link: "/help/pearos/flush/diskgenius.md",
        },
      ],
    },
    {
      title: "OpenWRT 上机指南",
      articles: [
        {
          title: "京东云无线宝亚瑟 AX1800 Pro 刷机教程",
          link: "/help/pearos_openwrt/ax1800pro.md",
        },
      ],
    },
    // {
    //   title: "其它三方插件",
    //   articles: [
    //     { title: "iStoreOS 上机教程", link: "/help/plugin/istore.md" },
    //   ],
    // },
  ];

  const data2 = [
    {
      title: "FAQ帮助指南",
      articles: [
        {
          title: "常见问题",
          link: "/help/faq/common_problem.md",
        },
      ],
    },
    {
      title: "收益",
      articles: [
        {
          title: "各种节点的收益发放及提现规则",
          link: "/help/income/paid_rule.md",
        },
      ],
    },
  ];

  // const filteredData = data3
  //   .map((category) => ({
  //     ...category,
  //     articles: category.articles.filter((article) =>
  //       article.title.toLowerCase().includes(searchTerm.toLowerCase())
  //     ),
  //   }))
  //   .filter((category) => category.articles.length > 0);

  useEffect(() => {
    onRequire();
  }, []);
  return (
    // <div>
    //   <EasyAnnounce notifications={notifications} />
    //   {/* <Title>闲小节点</Title>
    //   <SimpleLinkListItem title="如何本地绑定闲小节点" to="/home/help_basic_local"/>
    //   <SimpleLinkListItem title="如何远程绑定闲小节点" to="/home/help_basic_remote"/>
    //   <SimpleLinkListItem title="已适配设备列表" to="/home/doc/supporteddevices"/> */}

    //   <Title>CDN节点 & 优享节点</Title>
    //   <SimpleLinkListItem title="常见问题" to="/home/doc/common_problem" />
    //   <SimpleLinkListItem title="新用户绑定节点" to="/home/doc/bind_node" />
    //   {/* <SimpleLinkListItem title="CDN节点和优享节点的区别" to="/home/help_difference"/> */}
    //   {/* <SimpleLinkListItem title="购买系统盘" useHref={true} to=""
    //                             href="https://item.taobao.com/item.htm?id=613751308949" target="_blank"
    //                             rel="noopener noreferrer"/> */}
    //   <SimpleLinkListItem
    //     title="系统盘制作教程"
    //     to="/home/doc/make_system_drive"
    //   />
    //   {/* <SimpleLinkListItem title="Ubuntu Server 版系统网络配置教程" to="/home/doc/network_config"/> */}
    //   <SimpleLinkListItem
    //     title="PearOS Ubuntu 版系统网络配置教程"
    //     to="/home/doc/network_guide"
    //   />
    //   {/* <SimpleLinkListItem title="Windows 版 PCDN 安装教程" to="/home/doc/pcdn"/> */}

    //   <Title>收益</Title>
    //   <SimpleLinkListItem
    //     title="各种节点的收益发放及提现规则"
    //     to="/home/help_rule"
    //   />
    // </div>
    <>
      <Layout mainMaxWidth={1000}>
        <Container className={classes.container}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            className={classes.containerHeader}
          >
            <Typography variant="h3" className={classes.title}>
              帮助中心
            </Typography>
            {/* <div className={classes.searchContainer}>
            <Search
              value={searchTerm}
              placeholder="搜索帮助文档"
              onChange={handleSearchChange}
              className={classes.searchInput}
              size="medium"
            />
          </div> */}
          </Box>
          <Box pt={20}>
            <Box mb={10}>
              <DescriptionsHeader title="上机指南" />
              <Grid container spacing={2}>
                {data1.map((item, index) => (
                  <Grid key={item.title} item xs={12} sm={6} md={4}>
                    <HelpCenterCard
                      item={item}
                      onViewMore={() => {
                        window.open(item.articles[0].link, "_blank");
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box>
              <DescriptionsHeader title="其它" />
              <Grid container spacing={2}>
                {data2.map((item, index) => (
                  <Grid key={item.title} item xs={12} sm={6} md={4}>
                    <HelpCenterCard
                      item={item}
                      onViewMore={() => {
                        window.open(item.articles[0].link, "_blank");
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Container>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    notifications: (state.utils.notifications || {})["帮助"] || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(fetchNotifications("帮助"));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReward);
