import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./header";
import Footer from "./footer";
import { SiteRoutes } from "../../Routes";

const { SiteHeader } = Header;
// const { Content } = Layout

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    // margin: theme.spacing( 2),
    position: "relative",
  },
  main: {
    minWidth: 275,
    maxWidth: 1000,
    marginLeft: "auto",
    marginRight: "auto",
  },
  footer: {
    marginTop: theme.spacing(5),
  },
}));

const SiteLayout = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SiteHeader />

      <div className={classes.content}>
        <div className={classes.main}>
          <SiteRoutes />
          <div className={classes.footer}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteLayout;
