import React from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";
import DefaultIcon from "@material-ui/icons/DoubleArrow"; //LabelImportant

const useStyles = makeStyles((theme) => ({
  title: {
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    marginBottom: 2,
  },
  simpleTitle: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    marginBottom: 2,
  },
  leftIcon: {
    verticalAlign: "middle",
    marginRight: theme.spacing(0.5),
    color: "#808080",
  },
  titleContent: {
    verticalAlign: "middle",
    fontSize: 18,
  },
}));

export function Title(props) {
  const { Icon, children } = props;
  const classes = useStyles();
  const LeftIcon = Icon || DefaultIcon;

  return (
    <Typography className={classes.title} variant={"body1"}>
      <LeftIcon className={classes.leftIcon} />
      <span className={classes.titleContent}>{children}</span>
    </Typography>
  );
}

export function SimpleTitle(props) {
  const { children } = props;
  const classes = useStyles();
  return (
    <Typography className={classes.simpleTitle} variant={"body1"}>
      {children}
    </Typography>
  );
}
