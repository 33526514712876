import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Title } from "../../modules/title";
import { fetchNotifications } from "../../modules/utils/actions";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Box, Grid, makeStyles, Container } from "@material-ui/core";
// import Search from "../../common/search";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    maxWidth: 1000,
    minWidth: 275,
  },
  containerHeader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(7),
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontWeight: 500,
  },
  searchContainer: {
    width: 600,
    marginBottom: theme.spacing(2),
  },
  searchInput: {
    maxWidth: 600,
    width: "100%",
    padding: "6px 14px",
    boxSizing: "content-box",
    "& .MuiOutlinedInput-root": {
      borderRadius: 20,
      paddingRight: theme.spacing(2),
    },
  },
  sectionTitle: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
  popularList: {
    paddingLeft: 0,
  },
  contactButton: {
    marginTop: theme.spacing(2),
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 196,
    transition: "transform 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
    },
  },
  cardActions: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    // justifyContent: "flex-end",
  },
  //   header: {
  //     fontSize: 16,
  //     fontWeight: 600,
  //     display: "flex",
  //     alignItems: "center",
  //     "&:before": {
  //       content: '""',
  //       width: 4,
  //       height: 16,
  //       marginRight: theme.spacing(1),
  //       borderRadius: 4,
  //       backgroundColor: theme.palette.primary.main,
  //     },
  //   },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 2,
    paddingBottom: 2,
  },
  articleTitle: {
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function DownloadCard({ item, type }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {item.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {item.description}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          component="a"
          href={item.downloadLink}
          target="_blank"
        >
          下载
        </Button>
        {type === "otherTools" ? null : (
          <Button
            size="small"
            color="primary"
            component="a"
            href={item.documentLink}
            target="_blank"
          >
            文档
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

export function DescriptionsHeader({ title, end }) {
  //   const defaultClasses = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      {/* <Typography className={defaultClasses.header} variant="h6">
        {title}
      </Typography> */}
      <Title>{title}</Title>
      {end ? end : null}
    </Box>
  );
}

function MyReward({ onRequire, notifications }) {
  const classes = useStyles();

  const data1 = [
    {
      title: "IMG镜像烧录",
      description: "CDN节点是为您提供加速的节点，可以让您的网站速度更快",
      downloadLink:
        "https://pearos-1300587032.cos.ap-shanghai.myqcloud.com/Ubuntu/IMG/PearOS-Ubuntu-24.08.img.xz",
      documentLink: "/help/pearos/flush/img.md",
    },
    {
      title: "ISO镜像安装",
      description:
        "将ISO烧录到安装中介盘，再通过该中介盘安装到目标系统盘。亦适合服务器IPMI远程安装。",
      downloadLink:
        "https://pearos-1300587032.cos.ap-shanghai.myqcloud.com/Ubuntu/ISO/PearOS-Ubuntu-24.08.iso",
      documentLink: "/help/pearos/flush/iso.md",
    },
    {
      title: "DiskGenius镜像还原安装",
      description:
        "使用DiskGenius将分区备份文件写入到目标系统盘。适合熟悉DiskGenius的用户安装使用。",
      downloadLink:
        "https://pearos-1300587032.cos.ap-shanghai.myqcloud.com/Ubuntu/DG/PearOS-Ubuntu-24.08.zip",
      documentLink: "/help/pearos/flush/diskgenius.md",
    },
  ];
  const data2 = [
    {
      title: "Rufus",
      description:
        "将ISO烧录到安装中介盘，再通过该中介盘安装到目标系统盘。亦适合服务器IPMI远程安装。",
      downloadLink: "http://rufus.ie/zh/",
    },
    {
      title: "Etcher",
      description: "CDN节点是为您提供加速的节点，可以让您的网站速度更快",
      downloadLink: "https://etcher.balena.io/#download-etcher",
    },
    {
      title: "DiskGenius",
      description:
        "使用DiskGenius将分区备份文件写入到目标系统盘。适合熟悉DiskGenius的用户安装使用。",
      downloadLink: "https://www.diskgenius.cn/download.php",
    },
  ];

  useEffect(() => {
    onRequire();
  }, []);
  return (
    <>
      <Container className={classes.container}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          className={classes.containerHeader}
        >
          <Typography variant="h3" className={classes.title}>
            下载中心
          </Typography>
        </Box>
        <Box pt={20}>
          <Box mb={10}>
            <DescriptionsHeader title="固件下载" />
            <Grid container spacing={2}>
              {data1.map((item, index) => (
                <Grid key={item.title} item xs={12} sm={6} md={4}>
                  <DownloadCard item={item} key={index} />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box>
            <DescriptionsHeader title="其它工具" />
            <Grid container spacing={2}>
              {data2.map((item, index) => (
                <Grid key={item.title} item xs={12} sm={6} md={4}>
                  <DownloadCard type={"otherTools"} item={item} key={index} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    notifications: (state.utils.notifications || {})["帮助"] || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(fetchNotifications("帮助"));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReward);
