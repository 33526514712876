import React, { useMemo, useState } from "react";
import FolderTree from "./folderTree";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, Typography } from "@material-ui/core";
// import Search from "../../../common/search";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    top: "64px",
    alignSelf: "flex-start",
    width: "0",
    height: "calc(100vh - 64px)",
    marginRight: "0",
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    transition: "width 0.3s, opacity 0.3s",
    opacity: "0",
  },
  openPane: {
    flex: "0 0 auto",
    width: "300px",
    marginRight: "10px",
    opacity: "1",
  },
  drawerPane: {
    position: "static",
    height: "100%",
    marginRight: 0,
  },
  header: {
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
  },
}));

const getParentPath = (path) => {
  const parts = path.split("/").filter((p) => p !== "");
  if (parts.length === 0) return "";
  parts.pop();
  return parts.length === 0 ? "" : `/${parts.join("/")}`;
};

const NavigationPane = ({
  initialCurrentPath,
  rawFiles,
  isDrawer,
  onFileOpen,
  onToggleDrawer,
}) => {
  const classes = useStyles();
  // const [rawFiles] = useState([
  //   {
  //     name: "PearOS 上机指南",
  //     isDirectory: true,
  //     path: "/pearos",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //   },
  //   {
  //     name: "系统盘制作教程",
  //     isDirectory: false,
  //     path: "/pearos/make-system-drive.md",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //     size: 3610,
  //   },
  //   {
  //     name: "PearOS Ubuntu 版刷机教程（以下任一）",
  //     isDirectory: true,
  //     path: "/pearos/flush",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //   },
  //   {
  //     name: "方案一：ISO镜像安装",
  //     isDirectory: false,
  //     path: "/pearos/flush/iso.md",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //     size: 3111,
  //   },
  //   {
  //     name: "方案二：IMG镜像烧录",
  //     isDirectory: false,
  //     path: "/pearos/flush/img.md",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //     size: 1160,
  //   },
  //   {
  //     name: "方案三：DiskGenius镜像还原安装",
  //     isDirectory: false,
  //     path: "/pearos/flush/diskgenius.md",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //     size: 3435,
  //   },
  //   {
  //     name: "PearOS Ubuntu 版系统初始化教程",
  //     isDirectory: false,
  //     path: "/pearos/init.md",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //     size: 1847,
  //   },
  //   {
  //     name: "新用户绑定节点",
  //     isDirectory: false,
  //     path: "/pearos/bind-node.md",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //     size: 3610,
  //   },
  //   {
  //     name: "PearOS CentOS 版刷机教程（不推荐）",
  //     isDirectory: false,
  //     path: "/pearos/flush-centos.md",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //   },
  //   {
  //     name: "OpenWRT 上机指南",
  //     isDirectory: true,
  //     path: "/pearos-openwrt",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //   },
  //   {
  //     name: "京东云无线宝亚瑟 AX1800 Pro 刷机教程",
  //     isDirectory: false,
  //     path: "/pearos-openwrt/ax1800pro.md",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //   },
  //   {
  //     name: "其它三方插件",
  //     isDirectory: true,
  //     path: "/plugin",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //   },
  //   {
  //     name: "iStoreOS 上机教程",
  //     isDirectory: false,
  //     path: "/plugin/istore.md",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //   },
  //   {
  //     name: "FAQ帮助指南",
  //     isDirectory: true,
  //     path: "/faq",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //   },
  //   {
  //     name: "常见问题",
  //     isDirectory: false,
  //     path: "/faq/common-problem.md",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //     size: 3327,
  //   },
  //   {
  //     name: "收益",
  //     isDirectory: true,
  //     path: "/income",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //   },
  //   {
  //     name: "各种节点的收益发放及提现规则",
  //     isDirectory: false,
  //     path: "/income/paid-rule.md",
  //     updatedAt: "2024-09-09T10:30:00Z",
  //     size: 2708,
  //   },
  // ]);
  const [openNavigationPane, setOpenNavigationPane] = useState(true);

  const structuredFolders = useMemo(() => {
    if (!rawFiles) return []; // 处理空值情况

    // 增加路径分组缓存
    const grouped = rawFiles.reduce((acc, file) => {
      const parentPath = getParentPath(file.path);
      if (parentPath === file.path) {
        // 防止自引用路径
        console.error(`Detected circular path: ${file.path}`);
        return acc;
      }
      acc[parentPath] = acc[parentPath] || [];
      acc[parentPath].push(file);
      return acc;
    }, {});

    // 使用闭包维护已访问路径
    const visitedPaths = new Set();

    const buildTree = (currentPath) => {
      // 终止条件1：检测到循环路径
      if (visitedPaths.has(currentPath)) {
        console.error(`Circular reference detected at path: ${currentPath}`);
        return [];
      }
      visitedPaths.add(currentPath);

      // 终止条件2：无子节点时返回空数组
      const children = grouped[currentPath] || [];

      return (
        children
          // .sort((a, b) => a.name.localeCompare(b.name)) // 排序
          .map((child) => ({
            ...child,
            subDirectories: child.isDirectory
              ? buildTree(child.path) // 递归子路径
              : undefined, // 非目录不保留空数组
          }))
      );
    };

    try {
      return buildTree(""); // 从根路径开始
    } catch (error) {
      console.error("Directory tree build failed:", error);
      return []; // 返回安全值
    }
  }, [rawFiles]);

  // const [searchTerm, setSearchTerm] = useState("");

  // const handleSearchChange = (value) => {
  //   setSearchTerm(value);
  // };

  const handleToggleNavigationPane = () => {
    setOpenNavigationPane(!openNavigationPane);
  };

  return (
    <>
      <Box
        className={`${classes.root} ${openNavigationPane ? classes.openPane : ""} ${isDrawer ? classes.drawerPane : ""}`}
      >
        <Box width={300} height="100%" p={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.header}
          >
            <Typography variant="h6">OpenFogOS 帮助中心</Typography>
            <IconButton
              size="small"
              onClick={isDrawer ? onToggleDrawer : handleToggleNavigationPane}
            >
              <MenuOpenIcon />
            </IconButton>
          </Box>
          {/* <Box mb={2}>
            <Search
              value={searchTerm}
              placeholder="搜索帮助文档"
              onChange={handleSearchChange}
            />
          </Box> */}
          {structuredFolders && (
            <FolderTree
              initialCurrentPath={initialCurrentPath}
              folders={structuredFolders}
              isDrawer={isDrawer}
              onFileOpen={onFileOpen}
            />
          )}
        </Box>
      </Box>

      {!openNavigationPane ? (
        <Box
          position="sticky"
          top="64px"
          alignSelf="flex-start"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width={0}
          height={67}
        >
          <IconButton
            size="small"
            style={{ marginRight: !openNavigationPane ? "10px" : 0 }}
            onClick={handleToggleNavigationPane}
          >
            <MenuOpenIcon />
          </IconButton>
        </Box>
      ) : null}
    </>
  );
};

export default NavigationPane;
