import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, NavLink } from "react-router-dom";
import { view as Logout } from "../../modules/auth/logout";
import { useDispatch, useSelector } from "react-redux";
import DNSIcon from "@material-ui/icons/DnsOutlined";
import ExitIcon from "@material-ui/icons/ExitToApp";
import { logout } from "../../modules/auth/actions";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import IconSetting from "@material-ui/icons/SettingsOutlined";
import classNames from "classnames";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MoneyIcon from "@material-ui/icons/Money";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import StorageIcon from "@material-ui/icons/Storage";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    top: 0,
    zIndex: 2,
  },
  toolbar: {
    justifyContent: "space-between",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  golden: {
    color: theme.myColor.golden,
  },
  navList: {
    marginLeft: theme.spacing(2),
  },
  navLink: {
    color: theme.myColor.golden,
    padding: theme.spacing(0.5, 2),
    fontSize: "16px",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  activeLink: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  drawerList: {
    width: 250,
  },
  profileMenu: {
    position: "relative",
    height: 64,
    "&:hover .MuiPaper-root": {
      display: "block",
    },
  },
  profileMenuBtn: {
    marginLeft: theme.spacing(2),
  },
  profileMenuPaper: {
    display: "none",
    position: "absolute",
    top: "100%",
    right: "-10px",
    transform: "translate(0, -10px)",
    width: "10em",
    zIndex: 10,
  },
}));

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
  }));

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const onLogout = () => {
    dispatch(logout());
  };

  const navItems = [
    // TODO fix: 不带home导致个性化记忆功能key对不上
    { to: "/home/my_mine_nodes", text: "CDN设备收益", needAuth: true },
    { to: "/download", text: "下载" },
    { to: "/help", text: "帮助" },
  ];

  const drawerItems = [
    {
      type: "link",
      to: "/home/my_reward",
      text: "我的收益",
      icon: <AttachMoneyIcon />,
      isRequiresLogin: true,
    },
    {
      type: "link",
      to: "/home/my_withdraw",
      text: "提现",
      icon: <MoneyIcon />,
      isRequiresLogin: true,
    },

    { type: "divider", isRequiresLogin: true },
    {
      type: "link",
      to: "/home/my_mine_nodes",
      text: "CDN节点",
      icon: <DNSIcon />,
      isRequiresLogin: true,
    },
    {
      type: "link",
      to: "/home/my_advanced_nodes",
      text: "优享节点",
      icon: <StorageIcon />,
      isRequiresLogin: true,
    },
    { type: "divider", isRequiresLogin: true },
    {
      type: "link",
      to: "/home/certification",
      text: "实名认证",
      icon: <HowToRegIcon />,
      isRequiresLogin: true,
    },
    {
      type: "external",
      getHref: () =>
        `https://account.openfogos.com/update/password?u=${localStorage.getItem("openfogos_username")}`,
      text: "修改密码",
      icon: <IconSetting />,
      isRequiresLogin: true,
    },
    {
      type: "action",
      onClick: onLogout,
      text: "退出登录",
      icon: <ExitIcon />,
      isRequiresLogin: true,
    },
    { type: "divider", isRequiresLogin: true },
    {
      type: "link",
      to: "/download",
      text: "下载",
      icon: <GetAppIcon />,
    },
    {
      type: "link",
      to: "/help",
      text: "帮助",
      icon: <HelpOutlineIcon />,
    },
  ];

  return (
    <>
      <AppBar position="static" color="secondary" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Box display="flex" alignItems="center">
            {isMobile || isAuthenticated ? (
              <IconButton
                className={classNames(classes.menuButton, classes.golden)}
                aria-label="Menu"
                onClick={(e) => handleDrawerToggle(e, true)}
              >
                <MenuIcon />
              </IconButton>
            ) : null}
            {/* Logo */}
            <Typography
              variant="h6"
              className={classes.golden}
              component={Link}
              to="/"
            >
              OpenFogOS
            </Typography>

            <Hidden smDown>
              <nav className={classes.navList}>
                {navItems
                  .filter((item) => !item.needAuth || isAuthenticated)
                  .map((item) => (
                    <Button
                      key={item.to}
                      component={NavLink}
                      to={item.to}
                      className={classes.navLink}
                      activeClassName={classes.activeLink}
                    >
                      {item.text}
                    </Button>
                  ))}
              </nav>
            </Hidden>
          </Box>

          <>
            {isAuthenticated ? (
              <Box
                position="relative"
                display="flex"
                alignItems="center"
                className={classes.profileMenu}
              >
                <Box className={classes.profileMenuBtn}>
                  <Typography variant="body1" className={classes.golden}>
                    {localStorage.getItem("openfogos_username")}
                  </Typography>
                </Box>

                <Paper className={classes.profileMenuPaper}>
                  <List component="nav">
                    <ListItem
                      component="a"
                      href={
                        "https://account.openfogos.com/update/password?u=" +
                        localStorage.getItem("openfogos_username")
                      }
                      target="_self"
                      button
                    >
                      <ListItemText primary={"修改密码"} />
                    </ListItem>
                    <ListItem
                      component={Link}
                      to={"/home/certification"}
                      button
                    >
                      <ListItemText primary={"实名认证"} />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={onLogout}>
                      <ListItemText primary="退出登录" />
                    </ListItem>
                  </List>
                </Paper>
              </Box>
            ) : (
              <Box>
                <Button
                  component="a"
                  href={
                    process.env.REACT_APP_ACCOUNT_SERVER +
                    "/login?" +
                    encodeURIComponent(
                      `source=${document.location.host}&title=OpenFogOS`
                    )
                  }
                  target="_self"
                  className={classes.golden}
                  size="large"
                >
                  登录
                </Button>
                <Button
                  component="a"
                  href={
                    process.env.REACT_APP_ACCOUNT_SERVER +
                    "/signup?" +
                    encodeURIComponent(
                      `source=${document.location.host}&` +
                        window.location.search.substr(1)
                    )
                  }
                  target="_self"
                  className={classes.golden}
                  size="large"
                >
                  注册
                </Button>
              </Box>
            )}
          </>
        </Toolbar>
      </AppBar>

      {isMobile || isAuthenticated ? (
        <SwipeableDrawer
          open={drawerOpen}
          onClose={(e) => handleDrawerToggle(e, false)}
          onOpen={(e) => handleDrawerToggle(e, true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={(e) => handleDrawerToggle(e, false)}
            onKeyDown={(e) => handleDrawerToggle(e, false)}
          >
            <div className={classes.list}>
              <List className={classes.drawerList}>
                {drawerItems.map((item, index) => {
                  if (item.isRequiresLogin && !isAuthenticated) {
                    return null;
                  }
                  if (item.type === "divider") {
                    return <Divider key={`divider-${index}`} />;
                  }
                  const component =
                    item.type === "external"
                      ? "a"
                      : item.type !== "action"
                        ? Link
                        : null;
                  const commonProps = {
                    button: true,
                    component,
                    ...(item.type === "external" && {
                      href: item.getHref(),
                      target: "_blank",
                      rel: "noopener",
                    }),
                    ...(item.type === "link" && { to: item.to }),
                    ...(item.type === "action" && { onClick: item.onClick }),
                  };

                  return (
                    <ListItem
                      key={item.to || item.text || index}
                      {...commonProps}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </div>
        </SwipeableDrawer>
      ) : null}
    </>
  );
};

export default Header;
