import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@material-ui/core";

// 选项卡面板组件
function TabPanel({ children, value, index }) {
  return (
    <div hidden={value !== index}>
      <Box>
        <Typography component="div">{children}</Typography>
      </Box>
    </div>
  );
}

// 处理每个选项卡块
export function TabsComponent({ tabs, marked }) {
  const [value, setValue] = useState(0);

  return (
    <div>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={value}
        onChange={(e, newValue) => setValue(newValue)}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.title} />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          <div
            dangerouslySetInnerHTML={{ __html: marked?.parse(tab.content) }}
          />
        </TabPanel>
      ))}
    </div>
  );
}

export function parseContent(markdown) {
  const parts = [];
  const splitRegex = /(=== Tabs[\s\S]*?=== Tabs)/g;
  let lastIndex = 0;
  let match;

  while ((match = splitRegex.exec(markdown)) !== null) {
    const start = match.index;
    const end = splitRegex.lastIndex;
    const before = markdown.substring(lastIndex, start);
    if (before) parts.push({ type: "text", content: before });

    const block = match[0];
    if (block.startsWith("=== Tabs")) {
      const tabsContent = block.replace(/=== Tabs|\s*%%%$/g, "").trim();
      const lines = tabsContent.split("\n");
      const tabs = [];
      let currentTab = null;

      lines.forEach((line) => {
        const tabMatch = line.match(/^%%% tab (.*)/);
        if (tabMatch) {
          if (currentTab) tabs.push(currentTab);
          currentTab = { title: tabMatch[1].trim(), content: [] };
        } else if (currentTab) {
          currentTab.content.push(line);
        }
      });

      if (currentTab) tabs.push(currentTab);
      parts.push({
        type: "tabs",
        tabs: tabs.map((tab) => ({
          title: tab.title,
          content: tab.content.join("\n").trim(),
        })),
      });
    }
    lastIndex = end;
  }

  const after = markdown.substring(lastIndex);
  if (after) parts.push({ type: "text", content: after });
  return parts;
}
